.entry-header-area,
.cart-main-area {
    overflow: hidden;
}

.entry-header-title h2 {
    font-weight: 400;
    color: #333;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.entry-header-area {
    padding-bottom: 20px;
}

.entry-header-area .ntry-header-title h2 {
    font-size: 20px;
    margin: 0;
    text-transform: uppercase;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.table-content table {
    background: #fff none repeat scroll 0 0;
    border-color: #e5e5e5;
    border-radius: 0;
    border-style: solid;
    border-width: 1px 0 0 1px;
    margin: 0 0 50px;
    text-align: center;
    width: 100%;
}

.table-content table th,
.table-content table td {
    border-bottom: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}

.table-content table th {
    border-top: medium none;
    font-weight: normal;
    padding: 20px 10px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    color: #333;
}

.table-content table {
    text-align: center;
}

.table-content table td {
    border-top: medium none;
    font-size: 13px;
    padding: 20px 10px;
    vertical-align: middle;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.table-content table td.product-thumbnail {
    width: 130px;
}

.table-content table td.product-name a {
    color: #6f6f6f;
    font-size: 14px;
    font-weight: 700;
    margin-left: 5px;
    text-decoration: none;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    color: #333;
}

.table-content table td.product-price {
    width: 130px;
}

.table-content table td.product-quantity {
    width: 130px;
}

.table-content table td input {
    background: #e5e5e5 none repeat scroll 0 0;
    border: medium none;
    border-radius: 3px;
    color: #6f6f6f;
    font-size: 18px;
    font-weight: normal;
    height: 40px;
    padding: 0 5px 0 10px;
    width: 60px;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.table-content table td.product-subtotal {
    font-size: 14px;
    font-weight: bold;
    width: 120px;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.table-content table .product-price .amount {
    font-size: 15px;
    font-weight: 700;
    color: #333;
}

.table-content table td.product-remove {
    width: 150px;
}

.table-content table td.product-remove i {
    color: #919191;
    display: inline-block;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
}

.buttons-cart ul li {
    display: inline-block;
}

.btn.btn-sqr,
.buttons-cart ul li a,
.coupon form a {
    background: #259030 none repeat scroll 0 0;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    margin-right: 15px;
    padding: 0 15px;
    text-shadow: none;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.btn.btn-sqr:hover,
.buttons-cart ul li a:hover,
.coupon form a:hover {
    background: #225c28 none repeat scroll 0 0;
    color: #fff;
    text-decoration: none;
}

.coupon {
    margin-bottom: 40px;
    overflow: hidden;
    padding-bottom: 20px;
}

.coupon h3 {
    font-size: 14px;
    margin: 0 0 10px;
    text-transform: uppercase;
}

.coupon p {
    font-size: 14px;
    color: #777;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    color: #333;
}

.coupon form input {
    border: 1px solid #e5e5e5;
    float: left;
    height: 40px;
    margin: 0 6px 20px 0;
    max-width: 100%;
    padding: 0 0 0 10px;
    width: 170px;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    color: #333;
}
.coupon form input:focus{
     outline: none !important;
    border:1px solid #259030;
}
.coupon form a {
    float: left;
}

.cart_totals {
    float: left;
    text-align: right;
    width: 100%;
}

.cart_totals h2 {
    border-bottom: 2px solid #333;
    display: inline-block;
    font-family: "Open Sans", serif;
    font-size: 30px;
    font-weight: 400;
    margin: 0 0 35px;
    text-transform: capitalize;
}

.cart_totals table {
    border: medium none;
    float: right;
    margin: 0;
    text-align: right;
}

.cart_totals table th {
    border: medium none;
    font-size: 14px;
    font-weight: 700;
    padding: 0 20px 12px 0;
    text-align: right;
    text-transform: uppercase;
    vertical-align: top;
    color: #777;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    color: #333;
}

.cart_totals table td {
    border: medium none;
    padding: 0 0 12px;
    vertical-align: top;
}

.cart_totals table td .amount {
    color: #ec4445;
    float: right;
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
    text-align: right;
    text-transform: uppercase;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.cart_totals table td ul#shipping_method {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.cart_totals table td ul#shipping_method li {
    float: left;
    margin: 0 0 10px;
    padding: 0;
    text-indent: 0;
    width: 100%;
    color: #333;
    font-size: 13px;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.cart_totals table td ul#shipping_method li input {
    margin: 0;
    position: relative;
    top: 2px;
}

.shipping a {
    color: #333;
    font-family: "Open Sans", serif;
    font-size: 13px;
    font-weight: 400;
}

.cart_totals table tr.order-total th,
.cart_totals table tr.order-total .amount {
    font-size: 20px;
    text-transform: uppercase;
    white-space: nowrap;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.cart_totals table td {
    border: medium none;
    padding: 0 0 12px;
    vertical-align: top;
}

.wc-proceed-to-checkout a {
    background: #259030 none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    height: 50px;
    line-height: 50px;
    margin-top: 20px;
    padding: 0 30px;
    text-transform: uppercase;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    text-decoration: none;
}
.wc-proceed-to-checkout a:hover{
    background: #225c28 none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    height: 50px;
    line-height: 50px;
    margin-top: 20px;
    padding: 0 30px;
    text-transform: uppercase;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    text-decoration: none;
     transition: all 0.3s ease 0s;
}