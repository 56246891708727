.wishlist-title h2 {
    color: #252525;
    font-size: 30px;
    font-weight: 300;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.wishlist-table table {
    background: #fff none repeat scroll 0 0;
    border-color: #e8e8e9;
    border-radius: 0;
    border-style: solid;
    border-width: 1px 0 0 1px;
    margin: 20px 0 50px;
    width: 100%;
}

.wishlist-table table th,
.wishlist-table table td {
    border-bottom: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    font-size: 14px;
    padding: 15px 10px;
    text-align: center;
    color: #333;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.wishlist-table table .product-remove {
    padding: 0 15px;
    width: 20px;
}

.wishlist-table table th {
    font-weight: 600;
}

.wishlist-table table .product-thumbnail {
    width: 150px;
}

.wishlist-table table .product-add-to-cart {
    width: 240px;
}

.wishlist-table table .product-remove {
    padding: 0 15px;
    width: 20px;
}

.wishlist-table table .product-remove>a {
    font-size: 25px;
    color: #333;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.wishlist-table table .product-remove>a:hover {
    color: #259030;
}

.wishlist-table table .product-thumbnail {
    width: 150px;
}

.product-name a {
    color: #333;
    transition: .3s;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.product-name a:hover {
    color: #259030;
    text-decoration: none;
}

.wishlist-table table td.product-price .amount {
    font-weight: 700;
}

.wishlist-table table .wishlist-in-stock {
    color: #ec4445;
}

.wishlist-table table .product-add-to-cart {
    width: 240px;
}

.wishlist-table table .product-add-to-cart>a {
    background: #000 none repeat scroll 0 0;
    color: #fff;
    display: block;
    font-weight: 700;
    padding: 10px 56px;
    text-transform: uppercase;
    width: 260px;
    font-family: 'Rufina', serif;
    font-weight: 400;
}

.wishlist-table table .product-add-to-cart>a:hover {
    background: #F07C29 none repeat scroll 0 0;
}

.wishlist-share {
    margin: 30px 0;
}

.wishlist-share-title {
    color: #252525;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Rufina', serif;
    font-weight: 400;
}

.wishlist-share ul li {
    display: inline-block;
    margin: 0 5px;
}

.wishlist-share ul li a {
    color: #444;
    font-size: 20px;
    text-decoration: none;
    transition: all 0.3s ease 0s;
    font-family: 'Rufina', serif;
    font-weight: 400;
}

.wishlist-share ul li a:hover {
    color: #F07C29;
}