body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800|Rufina:400,700');

@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300&display=swap');

/* @font-face {
  font-family: 'LiHasanRongdhonuUnicode';
  src: url('LiHasanRongdhonuUnicode.woff2') format('woff2'),
       url('LiHasanRongdhonuUnicode.woff') format('woff');
} */
@font-face {
  font-family: 'myFont';
  src: URL('./myFont.ttf') format('truetype');
}

@font-face {
  font-family: 'ShobujBorno';
  src: URL('./ShobujBorno.ttf') format('truetype');
}

/* #mobile-menu-active {
  display: none;
} */

/*----------------------------------------*/
/*  Header top area
/*----------------------------------------*/
ul {
  list-style: outside none none;
  margin-bottom: 0 !important;
  padding: 0
}
.header-top-area {
  background: #259030;
}

.account-area ul>li {
  display: inline-block;
  margin-right: 22px;
  padding: 8px 0 12px;
  position: relative;
}
.language-area ul>li:last-child,
.account-area ul>li:last-child {
    margin-right: 0px;
}

.language-area ul li a,
.account-area ul li a {
    color: #ffffff;
    font-size: 13px;
    text-decoration: none;
    text-transform: capitalize;
    font-family: 'Open Sans', sans-serif;
    transition: .3s;
}

.language-area ul li a i,
.account-area ul li a i {
  padding-left: 10px;
}

.account-area ul li {
  position: relative;
}
.account-area ul>li::before {
  background: #666 none repeat scroll 0 0;
  content: "";
  height: 12px;
  position: absolute;
  right: -13px;
  top: 50%;
  width: 1px;
  transform: translateY(-50%);
}

.account-area ul li:last-child:before {
  display: none;
}

.account-area ul li a:hover,
.language-area ul li a:hover {
    color: #f07c29;
}
.account-area ul li .header-sub ul li::before {
  display: none;
}
/*----------------------------------------*/
/*  Logo Bar
/*----------------------------------------*/
.header-search {
  /* margin-top: 7px; */
}
.header-search form {
  position: relative;
}
.header-search form input {
  background: #fff none repeat scroll 0 0;
  border: 0 none;
  color: #333;
  font-size: 14px;
  height: 42px;
  line-height: 40px;
  margin: 0;
  padding-left: 10px;
  width: 100%;
  border-radius: 5px;
  padding-right: 47px;
}
.header-search form a {
  background: #259030 none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  height: 42px;
  line-height: 40px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 42px;
  border-radius: 0 5px 5px 0px;
}
.header-search form a:hover {
  background: #232323 none repeat scroll 0 0;
}
.logo-area a {
  color: #666666;
}

.logo-area a:hover{
  color: #259030;
}

.logobar .mini-cart {
  float: left;
  display: inline-block;
}

.logobar .mini-cart ul li a {
  color: #666666;
  display: inline-block;
  font-size: 14px;
  line-height: 25px;
  text-transform: capitalize;
  transition: .3s;
  text-decoration: none;
}

.logobar .mini-cart ul li a i {
  padding-right: 8px;
}

.logobar .mini-cart ul li a:hover {
  color: #259030;
}
.logobar .my-cart {
  display: inline-block;
  float: right;
  margin-top: -8px;
}
.logobar .my-cart ul li {
  position: relative;
}
.logobar .my-cart ul>li>a {
  color: #444444;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  padding: 10px 0;
  text-transform: uppercase;
  text-decoration: none;
  transition: .3s;
}
.logobar .my-cart ul>li:hover a {
  color: #259030;
}
.logobar .my-cart ul li>a>i {
  font-size: 30px;
  padding-right: 30px;
  margin-top: 10px;
}
.logobar .my-cart ul li>span {
  background: #259030 none repeat scroll 0 0;
  border-radius: 50%;
  bottom: 0;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  height: 30px;
  left: 24px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  width: 30px;
}
.mini-cart-sub {
  position: absolute;
  background-color: #fff;
  border: medium none;
  border-radius: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  display: block;
  left: auto;
  top: 150%;
  opacity: 0;
  padding: 20px;
  right: 0;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 280px;
  z-index: 99999;
}
.logobar .my-cart ul>li:hover .mini-cart-sub {
  opacity: 1;
  visibility: visible;
  top: 110%;
}
.cart-product .single-cart {
  display: flex;
  margin-bottom: 15px;
  overflow: hidden;
}
.cart-product .single-cart .cart-img {
  flex: 0 0 65px;
  margin-right: 15px;
}
.cart-product .single-cart .cart-img a img {
  width: 100%;
}

.cart-product .single-cart .cart-info {
  overflow: hidden;
  padding-right: 10px;
  position: relative;
}
.cart-icon {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}
.cart-icon a i {
  color: #232323;
  transition: .3s;
}
.cart-icon a:hover i {
  color: #f07c29;
}

.cart-product .single-cart .cart-info h5 {
  margin-bottom: 0px;
}

.logobar .my-cart ul>li .cart-product .single-cart .cart-info h5 a {
  color: #666;
  font-family: Rufina;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.logobar .my-cart ul>li .cart-product .single-cart .cart-info h5 a:hover,
.logobar .my-cart ul>li .cart-bottom a:hover {
    color: #24902f;
}
.cart-product .single-cart .cart-info p {
  margin-bottom: 0;
  font-family: Rufina;
  font-size: 15px;
}

.cart-totals {
  margin-bottom: 15px;
}

.cart-totals h5 {
  color: #606060;
  display: block;
  font-size: 15px;
  font-weight: 400;
  font-family: Rufina;
  margin: 0;
  text-transform: uppercase;
}

.cart-totals h5 span {
  display: block;
  float: right;
}
.logobar .my-cart ul>li .cart-bottom a.view-cart {
  margin-bottom: 15px;
}
.logobar .my-cart ul>li .cart-bottom a {
  background-color: #606060;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 36px;
  line-height: 24px;
  padding: 7px 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  transition: .3s;
  font-family: Rufina;
}

/*----------------------------------------*/
/*  Menu Bar
/*----------------------------------------*/
.main-menu-area {
  background: #fff none repeat scroll 0 0;
}
.main-menu-area.sticky {
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
}
.main-menu-area.sticky-header-1.sticky:hover {
  background: #fff none repeat scroll 0 0;
}
.menu-area {
  float: center;
  display: inline-block;
}

.menu-area ul li {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.menu-area ul li>a,
.safe-area a {
    background: transparent none repeat scroll 0 0;
    color: black;
    display: block;
    font-size: 19px;
    font-weight: 500;
    font-style: normal;
    line-height: 25px;
    padding: 13px 20px;
    position: relative;
    text-transform: uppercase;
    z-index: 4;
}
.menu-area ul li a i {
  padding-left: 10px;
}
/* .menu-area ul li:before,
.safe-area:before {
    background: #f07c29 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: -8px;
    transition: .5s;
    z-index: 1;
} */
/* .menu-area ul li:hover:before,
.menu-area ul li.active:before,
.safe-area:hover:before {
    opacity: 1;
}

.menu-area ul li:hover a:before,
.menu-area ul li.active a:before {
    width: 220%;
    opacity: 1;
} */
/* .menu-area ul li a:after,
.safe-area a:after {
    border: 4px solid transparent;
    content: "";
    position: absolute;
    right: -8px;
    top: -8px;
    transition: .5s;
    border-bottom-color: #a74600;
    border-left-color: #a74600;
    opacity: 0;
}
.menu-area ul li:hover a:after,
.menu-area ul li.active a:after,
.safe-area:hover a:after {
    opacity: 1;
} */

.menu-area ul li a::before {
  display: none;
}
.menu-area ul li a::after {
  display: none;
}
.safe-area {
  display: inline-block;
  float: center;
  position: relative;
}

.menu-area ul li::before,
.safe-area a::before {
    display: none;
}
.safe-area a {
  font-size: 16px;
  font-weight: 700;
}

.menu-area ul li {
  margin-right: 8px;
}
.menu-area ul li:hover>a,
.menu-area ul li.active>a {
    color: #499e46;
}
.menu-area ul li .mega-menu span a:hover,
.menu-area ul li .sub-menu ul li a:hover {
    color: #499e46;
}

/* .safe-area a {
  position: relative;
  z-index: 4
} */
.menu-area ul li .mega-menu {
  background: #fff none repeat scroll 0 0;
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  position: absolute;
  z-index: 6;
  width: 910px;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: 0 0 0;
  transition: .5s;
  visibility: hidden;
}
.menu-area ul li:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}

.menu-area ul li .mega-menu span {
  float: left;
  width: 25%;
  padding-right: 20px;
}
.menu-area ul li .mega-menu span a.title {
  border-bottom: 1px solid #e5e5e5;
  color: #393939;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 12px;
  padding-bottom: 15px;
  text-transform: uppercase;
}
.menu-area ul li .mega-menu span a {
  display: block;
  color: #777;
  font-size: 14px;
  line-height: 30px;
  text-transform: capitalize;
  transition: .3s;
  text-decoration: none;
}
.menu-area ul li .mega-menu span a:hover,
.menu-area ul li .sub-menu ul li a:hover {
    color: #24902f;
}

.menu-area ul li .mega-menu span a:before {
    display: none;
}

.menu-area ul li .mega-menu span a:after {
    display: none;
}

.menu-area ul li .mega-menu-2 {
    width: 690px;
}

.menu-area ul li .mega-menu-2 span {
    width: 33.33%;
}

.menu-area ul li .mega-menu-3 {
    width: 460px;
}

.menu-area ul li .mega-menu-3 span {
    width: 50%;
}

.menu-area ul li .sub-menu {
  background: #fff;
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 45;
  padding: 10px 20px 15px;
  position: absolute;
  z-index: 6;
  transform: scaleY(0);
  transform-origin: 0 0 0;
  transition: .5s;
  width: 200px;
  opacity: 0;
  visibility: hidden;
}
.menu-area ul li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.menu-area ul li .sub-menu ul li {
  display: block;
}

.menu-area ul li .sub-menu ul li:before {
  display: none;
}
.menu-area ul li .sub-menu ul li a {
  color: #777;
  display: block;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0 3px;
  text-decoration: none;
  transition: .3s;
  text-transform: capitalize;
}

.menu-area ul li .sub-menu ul li a:before {
  display: none;
}

.menu-area ul li .sub-menu ul li a:after {
  display: none;
}
.main-menu-area.sticky {
  left: 0;
  position: fixed;
  top: 0;
  transition: .5s;
  width: 100%;
  z-index: 99999999;
}
/*----------------------------------------*/
/*  3. Banner area
/*----------------------------------------*/

.info-container{
  max-width: 940px !important;
}

.single-banner {
  display: flex;
}

.single-banner .banner-img {
  margin-right: 12px;
  margin-top: 4px;
}

.single-banner .banner-img {
  flex: 0 0 32px;
  margin-top: 4px;
}

.single-banner .banner-text {}

.single-banner .banner-text h4 {
  color: #222;
  font-family: "Rufina", serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;
}

.single-banner .banner-text p {
  color: #333;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
}

.banner-img-2 a {
  border-radius: 3px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.banner-img-2 a img {
  width: 100%;
  transform: scale(1);
  transition: .5s;
}

.banner-img-2:hover a img {
  transform: scale(1.1);
}

.banner-shadow-hover {
  position: relative;
}

.banner-shadow-hover::before {
  bottom: 0;
  content: "";
  left: 0;
  margin: 0 auto;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 300ms ease-in 0s;
}

.banner-shadow-hover:hover::before {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.7) inset;
  transition: all 300ms ease-in 0s;
}

.banner-area-5 .banner-text {
  left: 5%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.banner-area-5 .banner-text h3 {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 7px;
  text-transform: capitalize;
}

.banner-area-5 .banner-text h2 {
  color: #fff;
  display: block;
  font-size: 28px;
  line-height: 1;
  margin-right: -218px;
  text-transform: uppercase;
  font-weight: 400;
}

/*----------------------------------------*/
/*  4. Slider area
/*----------------------------------------*/

.single-slider {
  position: relative;
}

.slider-img img a img {
  width: 100%;
}

.slider-content h1 {
  color: #333;
  font-size: 80px;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
  font-family: 'Rufina', serif;
  font-weight: 400;

}

.slider-content h2 {
  color: #333333;
  font-size: 100px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 25px;
  text-transform: capitalize;
  font-family: 'Rufina', serif;
  font-weight: 400;
  
}

.slider-content h3 {
  color: #666;
  font-size: 30px;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.slider-content a {
  background: #499e46 none repeat scroll 0 0;
  border: 2px solid #499e46;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 43px;
  padding: 10px 38px;
  text-transform: none;
  font-family: 'Rufina', serif;
  font-weight: 400;
  text-decoration: none;
  box-shadow: 2px 2px 2px 2px #a8c0ba;
}

.slider-content a:hover {
  background: #333 none repeat scroll 0 0;
  border-color: #333;
  box-shadow: 2px 2px 2px 2px #a8c0ba;
  transition: all 300ms ease-in 0s;
}

.slider-content-2 h1 {
  font-size: 40px;
  text-transform: none;
}

.slider-content-2 h2 {
  color: #fff;
  font-size: 80px;
  text-transform: none;
}

.slider-active.owl-carousel .owl-nav .owl-prev {
  background-color: transparent;
  color: #fff;
  display: block;
  font-size: 60px;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  left: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease-in-out 0s;
  z-index: 9;
  line-height: 1;
}

.slider-active.owl-carousel .owl-nav .owl-next {
  background-color: transparent;
  color: #fff;
  display: block;
  font-size: 60px;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  left: auto;
  right: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease-in-out 0s;
  z-index: 9;
  line-height: 1;
}

.slider-active.owl-carousel .owl-nav .owl-prev:hover,
.slider-active.owl-carousel .owl-nav .owl-next:hover {
  color: #259030;
}

.slider-active.owl-carousel .owl-dots .owl-dot span {
  background: #fff none repeat scroll 0 0;
  border-radius: 50px;
  display: block;
  height: 10px;
  margin: 5px;
  opacity: 1;
  transition: all 0.3s ease 0s;
  width: 10px;
}

.slider-active.owl-carousel .owl-dots .owl-dot.active span {
  background: #259030 none repeat scroll 0 0;
  width: 32px;
}

.slider-active.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}

.slider-active.owl-carousel .owl-dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: .5s;
}

.slider-area:hover .slider-active.owl-carousel .owl-dots {
  opacity: 1;
  visibility: visible;
}

.slider-area:hover .slider-active.owl-carousel .owl-nav .owl-prev,
.slider-area:hover .slider-active.owl-carousel .owl-nav .owl-next {
  opacity: 1;
  visibility: visible;
}

.owl-item .slider-animated-1 * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-item.active .slider-animated-1 h1 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  
}

.owl-item.active .slider-animated-1 h2 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  
}

.owl-item.active .slider-animated-1 h3 {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

.owl-item.active .slider-animated-1 a {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

.home-2 .owl-item.active .slider-animated-1 a,
.home-5 .owl-item.active .slider-animated-1 a {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

.home-2 .owl-item.active .slider-animated-1 p,
.home-5 .owl-item.active .slider-animated-1 p {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

/*----------------------------------------*/
/*  5. Product area
/*----------------------------------------*/
.section-title h2 {
    display: inline-block;
    font-size: 32px;
    font-weight: 700;
    line-height: 30px;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.section-title p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: 'Rufina', serif;
    color: #333;
}

.tab-menu ul li {
    display: inline-block;
    margin-right: 35px;
}

.tab-menu ul li a {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    padding-bottom: 5px;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
}

.tab-menu ul li a:before {
    background: #259030 none repeat scroll 0 0;
    bottom: -10px;
    content: "";
    height: 2px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: 0;
    transition: all 0.5s ease-in-out 0s;
    width: 100%;
}

.tab-menu ul li a:hover:before,
.tab-menu ul li a.active:before {
    opacity: 1;
    bottom: 0;
    visibility: visible;
}

.product-wrapper {
    position: relative;
    overflow: hidden;
}

.product-img,
.most-product-img {
    position: relative;
    overflow: hidden;
}

/* .product-img::after,
.most-product-img::after,
.banner-img-3::after {
    background: rgba(255, 255, 255, 0.61) none repeat scroll 0 0;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    top: 0;
    transition: all 0.5s ease-in-out 0s;
    width: 100%;
    pointer-events: none;
} */

.product-wrapper:hover .product-img::after,
.single-most-product:hover .most-product-img::after,
.single-deal:hover .banner-img-3::after {
    height: 100%;
    opacity: 1;
}

.product-img a img {
    width: 100%;
}

.quick-view {
    /* left: 130px; */
    position: absolute;
    right: 6%;
    text-align: center;
    top: 11%;
    transform: translateY(-50%);
    z-index: 999;
    transition: all .5s ease 0s;
    opacity: 0;
}
.quick-view2 {
  /* left: 130px; */
  position: absolute;
  right: 6%;
  text-align: center;
  top: 31%;
  transform: translateY(-50%);
  z-index: 999;
  transition: all .5s ease 0s;
  opacity: 0;
}
.quick-view3 {
  /* left: 130px; */
  position: absolute;
  right: 6%;
  text-align: center;
  top: 51%;
  transform: translateY(-50%);
  z-index: 999;
  transition: all .5s ease 0s;
  opacity: 0;
}

.product-wrapper:hover .quick-view {
    opacity: 1;
}
.product-wrapper:hover .quick-view2 {
    opacity: 1;
}
.product-wrapper:hover .quick-view3 {
  opacity: 1;
}
.quick-view a {
    background-color: #fff;
    border-radius: 5%;
    color:  #259030;
    display: inline-block;
    font-size: 15px;
    height: 35px;
    line-height: 35px;
    width: 35px;
    transform: scale(1);
    transition: all .5s ease 0s;
    white-space: normal;
    box-shadow: rgb(150 150 150/75%) 0px 0px 3px 2px;
    
}


.quick-view2 a {
  background-color: #fff;
  border-radius: 5%;
  color:  #259030;
  display: inline-block;
  font-size: 15px;
  height: 35px;
  line-height: 35px;
  width: 35px;
  transform: scale(1);
  transition: all .5s ease 0s;
  white-space: normal;
  box-shadow: rgb(150 150 150/75%) 0px 0px 3px 2px;
    
}
.quick-view3 a {
  background-color: #fff;
  border-radius: 5%;
  color:  #259030;
  display: inline-block;
  font-size: 15px;
  height: 35px;
  line-height: 35px;
  width: 35px;
  transform: scale(1);
  transition: all .5s ease 0s;
  white-space: normal;
  box-shadow: rgb(150 150 150/75%) 0px 0px 3px 2px;

}

.quick-view a:hover{
  background-color: #259030;
  color: #fff;
}
.quick-view2 a:hover{
  background-color: #259030;
  color: #fff;
}

.quick-view3 a:hover{
  background-color: #259030;
  color: #fff;
 
}

.product-wrapper:hover .quick-view a {
    transform: scale(1);
}

.product-wrapper:hover .quick-view2 a {
    transform: scale(1);
}

.product-rating ul li {
    display: inline-block;
}

.product-rating ul li a {
    color: #24902f;
    font-size: 12px;
}

.product-details h4 {
    margin-bottom: 10px;
    margin-top: 13px;
}

.product-details h4 a {
    color: #333333;
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
    overflow: hidden;
    padding: 0;
    text-transform: capitalize;
    transition: .3s;
    text-align: left;
    text-decoration: none;
    /* font-family: 'Rufina', serif; */
}

.product-details h4 a:hover,
.product-link .product-button a:hover,
.product-link .add-to-link ul li a:hover {
    color: #24902f;
}

.product-price ul li {
    display: inline-block;
    color: #259030;
    font-size: 20px;
    font-weight: 600;
    /* font-family: 'Rufina', serif;
    font-style: italic; */
   
}

.product-price ul li span {
    color: #666;
    padding-right: 5px;
    font-family: 'Rufina', serif;
    font-weight: 400;
    font-size: 16px;
}

.product-price ul li.old-price {
    color: #666;
    font-size: 15px;
    font-weight: 400;
    margin-left: 5px;
    text-decoration: line-through;
}

.product-wrapper:hover .product-price {
    opacity: 1;
    visibility: visible;
}

.product-price {
    transition: all 0.5s ease 0s;
}

.product-link {
    bottom: 0px;
    left: 0;
    overflow: hidden;
    padding-bottom: 8px;
    position: absolute;
    right: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
    margin-bottom: 1px;
}

.product-link:after {
    background: #cccccc none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    left: 50%;
    opacity: 1;
    position: absolute;
    transition: .5s;
    width: 0;
    right: 50%;
}

.product-wrapper:hover .product-link:after {
    width: 100%;
    left: 0;
    right: 0;
}

.product-wrapper:hover .product-link {
    opacity: 0;
    visibility: hidden;
}

.product-link .product-button {
    display: inline-block;
    float: center;
    position: relative;
    transition: .5s;
    transform: scale(0.6);
    z-index: 2;
}

.product-link .product-button a {
    border-radius: 0;
    color: #333;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    text-transform: capitalize;
    font-family: 'Rufina', serif;
    font-weight: 400;
    transition: .3s;
}

.product-link .product-button a i {
    padding-right: 8px;
}

.product-link .add-to-link {
    display: inline-block;
    float: right;
    position: relative;
    transition: .5s;
    transform: scale(0.6);
    z-index: 3;
}

.product-link .add-to-link ul li {
    display: inline-block;
}

.product-link .add-to-link ul li a {
    display: inline-block;
    color: #333;
    font-size: 18px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    width: 30px;
    transition: .3s;
    text-align: center;
}

.product-wrapper:hover .product-link .product-button {
    transform: scale(1);
}

.product-wrapper:hover .product-link .add-to-link {
    transform: scale(1);
}

.product-flag {
    left: 0;
    position: absolute;
    top: 0;
}


.product-flag li {
    line-height: 16px;
}


.product-flag ul li>span {
    background: #f07c29 none repeat scroll 0 0;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 0 6px;
    text-align: center;
    text-transform: uppercase;
    z-index: 100;
    font-weight: 400;
    position: relative;
    font-family: "Open Sans", sans-serif;
}

.product-flag ul li>span.discount-percentage {
    background: #259030 none repeat scroll 0 0;
    line-height: 1;
}

.product-flag ul li>span:before {
    border-color: #f07c29 transparent transparent #f07c29;
    border-style: solid;
    border-width: 4.5px 3px;
    content: "";
    position: absolute;
    right: -6px;
    top: 0;
}

.product-flag ul li>span:after {
    border-color: transparent transparent #f07c29 #f07c29;
    border-style: solid;
    border-width: 4.5px 3px;
    bottom: 0;
    content: "";
    position: absolute;
    right: -6px;
}

.product-flag ul li>span.discount-percentage:before {
    border-color: #259030 transparent transparent #259030;
}

.product-flag ul li>span.discount-percentage:after {
    border-color: transparent transparent #259030 #259030;
}

.tab-content>.tab-pane {
    display: block;
    overflow: hidden;
    height: 0;
}

.tab-content>.active {
    display: block;
    overflow: visible;
    height: auto;
}

.tab-active.owl-carousel .owl-nav button,
.post-active.owl-carousel .owl-nav button,
.bestseller-active.owl-carousel .owl-nav button,
.tab-active-3.owl-carousel .owl-nav button,
.hot-sell-active.owl-carousel .owl-nav button {
    background: #f6f6f6 none repeat scroll 0 0;
    border-radius: 4px;
    color: #666;
    font-size: 50px;
    height: 69px;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s;
    width: 69px;
    z-index: 1;
    left: 10px;
    visibility: hidden;
}

.tab-active.owl-carousel .owl-nav button i,
.post-active.owl-carousel .owl-nav button i,
.bestseller-active.owl-carousel .owl-nav button i,
.tab-active-3.owl-carousel .owl-nav button i,
.hot-sell-active.owl-carousel .owl-nav button i {
    position: relative;
    top: -5px;
}

.tab-active-3.owl-carousel .owl-nav button:hover,
.hot-sell-active.owl-carousel .owl-nav button:hover {
    background: #00abe0 none repeat scroll 0 0;
    color: #fff;
}

.tab-active.owl-carousel .owl-nav button:hover,
.post-active.owl-carousel .owl-nav button:hover,
.bestseller-active.owl-carousel .owl-nav button:hover {
    background: #259030;
    color: #fff;
}

.tab-active.owl-carousel .owl-nav button.owl-next,
.post-active.owl-carousel .owl-nav button.owl-next,
.bestseller-active.owl-carousel .owl-nav button.owl-next,
.tab-active-3.owl-carousel .owl-nav button.owl-next,
.hot-sell-active.owl-carousel .owl-nav button.owl-next {
    left: auto;
    right: 10px;
}

.tab-active:hover.owl-carousel .owl-nav button.owl-prev,
.post-active:hover.owl-carousel .owl-nav button.owl-prev,
.tab-active-3:hover.owl-carousel .owl-nav button.owl-prev,
.hot-sell-active:hover.owl-carousel .owl-nav button.owl-prev {
    left: 0;
}

.tab-active:hover.owl-carousel .owl-nav button.owl-next,
.tab-active-3:hover.owl-carousel .owl-nav button.owl-next,
.hot-sell-active:hover.owl-carousel .owl-nav button.owl-next {
    right: 0;
}

.bestseller-active:hover.owl-carousel .owl-nav button.owl-prev {
    left: -40px;
}

.bestseller-active:hover.owl-carousel .owl-nav button.owl-next {
    right: -40px;
}

.tab-active {
    overflow: hidden;
}

.tab-active:hover.owl-carousel .owl-nav button,
.post-active:hover.owl-carousel .owl-nav button,
.bestseller-active:hover.owl-carousel .owl-nav button,
.tab-active-3:hover.owl-carousel .owl-nav button,
.hot-sell-active:hover.owl-carousel .owl-nav button {
    opacity: 1;
    visibility: visible;
}

.bt {
    border-top: 1px solid #e5e5e5;
}

/*----------------------------------------*/
/*  3. Banner area
/*----------------------------------------*/
.single-banner {
  display: flex;
}

.single-banner .banner-img {
  margin-right: 12px;
  margin-top: 4px;
}

.single-banner .banner-img {
  flex: 0 0 32px;
  margin-top: 4px;
}

.single-banner .banner-text {}

.single-banner .banner-text h4 {
  color: #222;
  font-family: "Rufina", serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;
}

.single-banner .banner-text p {
  color: #333;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
}

.banner-img-2 a {
  border-radius: 3px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.banner-img-2 a img {
  width: 100%;
  transform: scale(1);
  transition: .5s;
}

.banner-img-2:hover a img {
  transform: scale(1.1);
}

.banner-shadow-hover {
  position: relative;
}

.banner-shadow-hover::before {
  bottom: 0;
  content: "";
  left: 0;
  margin: 0 auto;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 300ms ease-in 0s;
}

.banner-shadow-hover:hover::before {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.7) inset;
  transition: all 300ms ease-in 0s;
}

.banner-area-5 .banner-text {
  left: 5%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.banner-area-5 .banner-text h3 {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 7px;
  text-transform: capitalize;
}

.banner-area-5 .banner-text h2 {
  color: #fff;
  display: block;
  font-size: 28px;
  line-height: 1;
  margin-right: -218px;
  text-transform: uppercase;
  font-weight: 400;
}

/*----------------------------------------*/
/*  6. Bestseller area
/*----------------------------------------*/
.bestseller-area .bestseller-content {
  float: left;
  width: 50%;
  padding-right: 15px;
}

.bestseller-area .banner-img-2 {
  float: left;
  width: 50%;
  padding-left: 15px;
}

.bestseller-content h1 {
  font-size: 18px;
  margin-bottom: 52px;
  text-align: left;
  text-transform: uppercase;
}

.bestseller-content h2 {
  color: #333333;
  font-size: 70px;
  font-weight: 700;
  line-height: 1.1;
  text-align: right;
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.bestseller-content p.categories {
  color: #333;
  margin-top: 5px;
  text-align: right;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.bestseller-content p.categories a {
  color: #333;
  margin-left: 3px;
}

.bestseller-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 35px;
  text-align: right;
  font-family: "Rufina", serif;
  color: #333;
}

.bestseller-content .social-author {
  margin-top: 35px;
  text-align: right;
}

.bestseller-content .social-author ul li {
  display: inline-block;
}

.bestseller-content .social-author ul li a {
  background: #777 none repeat scroll 0 0;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  font-size: 15px;
  height: 37px;
  line-height: 35px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  width: 37px;
}

.bestseller-content .social-author ul li a:hover {
  background: #24902f none repeat scroll 0 0;
}

.bestseller-img {
  position: relative;
}

.single-bestseller .bestseller-img a img {
  width: 100%;
}

.single-bestseller .bestseller-text h3 {
  font-family: Open Sans;
  font-weight: 400;
  margin-bottom: 0;
}

.single-bestseller .bestseller-text h3 a {
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 13px;
  text-transform: capitalize;
  text-decoration: none;
}

.single-bestseller .bestseller-text .price ul li {
  display: inline-block;
}

.single-bestseller .bestseller-text .price ul li {
  padding-right: 5px;
}

.single-bestseller .bestseller-text .price ul li:last-child {
  padding-right: 0px;
}

.single-bestseller .bestseller-text .price ul li span {
  font-size: 15px;
  /* font-family: 'Rufina', serif; */
  transition: .3s;
  font-weight: 600;
  /* font-style: italic; */
}

.single-bestseller .bestseller-text .price ul li span.new-price {
  color: #259030 ;
  font-size: 16px;
}

.single-bestseller .bestseller-text .price ul li span.old-price {
  color: #909090;
  text-decoration: line-through;
}

.single-bestseller .bestseller-text h3 a:hover,
.most-product-content h4 a:hover,
.block-newsletter a:hover,
.post-content h3 a:hover {
  color: #24902f;
}
/*----------------------------------------*/
/*  7. Banner static area
/*----------------------------------------*/
/* 
.banner-static-area {
  background: rgba(0, 0, 0, 0) repeat scroll center center;
} */

/*----------------------------------------*/
/*  8. Most product area
/*----------------------------------------*/

.section-title-2 h3 {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
  text-transform: uppercase;
}

.product-active-2.owl-carousel .owl-nav button,
.product-active-3.owl-carousel .owl-nav button {
  background: #f6f6f6 none repeat scroll 0 0;
  border-radius: 4px;
  color: #666;
  font-size: 30px;
  height: 40px;
  left: 40px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 40px;
  z-index: 1;
}

.product-active-2.owl-carousel .owl-nav button i,
.product-active-3.owl-carousel .owl-nav button i {
  position: relative;
  top: -3px;
}

.product-active-2.owl-carousel .owl-nav button:hover {
  background: #259030 none repeat scroll 0 0;
  color: #fff;
}

.product-active-2.owl-carousel .owl-nav button.owl-next,
.product-active-3.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: 40px;
}

.product-active-2.owl-carousel:hover .owl-nav button.owl-next,
.product-active-3.owl-carousel:hover .owl-nav button.owl-next {
  right: 0px;
}

.product-active-2.owl-carousel:hover .owl-nav button.owl-prev,
.product-active-3.owl-carousel:hover .owl-nav button.owl-prev {
  left: 0px;
}

.product-active-2.owl-carousel:hover .owl-nav button,
.product-active-3.owl-carousel:hover .owl-nav button {
  opacity: 1;
  visibility: visible;
}

.single-most-product {
  overflow: hidden;
  padding-bottom: 18px;
}

.single-most-product:last-child {
  padding-bottom: 0px;
}

.most-product-img {
  float: left;
  width: 25%;
}

.most-product-img a img {
  width: 100%;
}

.most-product-content {
  float: left;
  width: 75%;
  padding-left: 15px;
}

.most-product-content h4 {
  margin-bottom: 0px;
}

.most-product-content h4 a {
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  text-transform: capitalize;
  font-family: 'Rufina', serif;
  font-weight: 400;
  text-decoration: none;
  transition: .3s;
}

.bd {
  /* border-bottom: 1px solid #e5e5e5; */
}

.block-newsletter {
  padding: 45px 25px 55px;
  position: relative;
  text-align: center;
  margin-top: 70px;
}

.block-newsletter:before {
  background: #259030 none repeat scroll 0 0;
  border-radius: 100%;
  content: "";
  height: 13px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -42px;
  width: 13px;
  z-index: 1;
}

.block-newsletter:after {
  background: #fff none repeat scroll 0 0;
  border: 2px solid #259030;
  content: "";
  height: 53px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -26px;
  transform: rotate(45deg);
  width: 53px;
  z-index: -1;
}

.block-newsletter h2 {
  color: #fff;
  display: block;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 28px;
  text-transform: uppercase;
}

.block-newsletter p {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  /* font-family: 'Rufina', serif; */
  font-weight: 400;
}

.block-newsletter form input {
  background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  height: 38px;
  line-height: 30px;
  padding: 10px;
  width: 100%;
}

.block-newsletter form input[type="text"]::-moz-placeholder {
  color: #fff !important;
  opacity: 1;
}

.block-newsletter form input[type="text"]::-webkit-input-placeholder {
  color: #fff !important;
  opacity: 1;
}

.block-newsletter form input[type="text"]::-ms-input-placeholder {
  color: #fff !important;
  opacity: 1;
}

.block-newsletter a {
  background: #fff none repeat scroll 0 0;
  border-radius: 4px;
  display: inline-block;
  color: #333;
  font-size: 14px;
  font-weight: 700;
  height: 38px;
  line-height: 38px;
  padding: 0 25px;
  text-align: center;
  text-transform: uppercase;
  transition: .5s;
  margin-top: 28px;
  text-decoration: none;
}
/*----------------------------------------*/
/*  9. Testimonial area
/*----------------------------------------*/

/* .testimonial-area {
  background: rgba(0, 0, 0, 0) url("") no-repeat scroll center center;
} */

.testimonial-img a {
  border: 1px solid #333;
  border-radius: 100%;
  display: inline-block;
  font-size: 45px;
  height: 97px;
  line-height: 97px;
  width: 97px;
  color: #333;
}

.testimonial-text p {
  color: #333;
  font-family: "Rufina", serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 41px 0 18px;
}

.testimonial-text a {
  color: #333;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
}

.testimonial-text a span {
  color: #f07c29;
  font-size: 16px;
  text-transform: uppercase;
}

.testimonial-active .owl-dots .owl-dot span {
  background: #333 none repeat scroll 0 0;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  transition: all 300ms ease-in 0s;
  width: 10px;
}

.testimonial-active .owl-dots .owl-dot.active span {
  background: #259030 none repeat scroll 0 0;
  transition: all 300ms ease-in 0s;
}

.testimonial-active .owl-dots {
  display: inline-block;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.testimonial-active .owl-dot {
  display: inline-block;
  margin: 0 4px;
}
.testimonial-img a:hover {
color: #333;
}
.testimonial-text a:hover {
  color: #259030;
}
/*----------------------------------------*/
/*  10. Recent post area
/*----------------------------------------*/

.post-active {
  overflow: hidden;
}

.single-post {
  overflow: hidden;
}

.single-post .post-img {
  overflow: hidden;
  position: relative;
}

.single-post .post-img a img {
  width: 100%;
  transform: scale(1);
  transition: .5s;
}

.single-post:hover a img {
  transform: scale(1.1);
}

.post-content h3 {
  margin-bottom: 0;
}

.post-content h3 a {
  color: #333;
  display: block;
  font-family: "Rufina", serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
  margin-top: 16px;
  position: relative;
  text-transform: none;
  transition: all 0.3s ease 0s;
}

.post-content p {
  border-bottom: 1px solid #e5e5e5;
  /* font-family: "Rufina", serif; */
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 1px;
  padding: 0 0 25px;
  color: #333;
}

.blog-date-time {
  background: #fff none repeat scroll 0 0;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  height: 54px;
  left: 10px;
  padding: 4px 5px 7px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 54px;
}

.blog-date-time span {
  color: #333;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
}

.blog-date-time span.moth-time {
  color: #333;
  display: block;
  font-size: 12px;
  padding-top: 5px;
  text-transform: uppercase;
}

.post-content span.meta-author {
  color: #333;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 13px;
  margin-bottom: 20px;
  text-transform: capitalize;
}
/*----------------------------------------*/
/*  11. Social group area
/*----------------------------------------*/

.social-group-area {
  background: #F6F6F6;
}

.section-title-3 h3 {
  color: #2d2d2d;
  display: block;
  font-size: 18px;
  margin-bottom: 30px;
  text-transform: uppercase;
  
}

.twitter-content .twitter-icon {
  float: left;
  width: 20%;
}

.twitter-content .twitter-icon a {
  background: #f07c29 none repeat scroll 0 0;
  border-radius: 100%;
  color: #fff;
  font-size: 24px;
  height: 70px;
  left: 0;
  line-height: 70px;
  text-align: center;
  width: 70px;
  display: inline-block;
}

.twitter-content .twitter-text {
  /* float: left; */
  width: 80%;
}

.twitter-text p {
  color: #333;
  font-family: "Rufina", serif;
  font-size: 16px;
  line-height: 21px;
  margin: 0;
  padding-top: 3px;
}

.twitter-text a {
  color: #f07c29;
  font-family: "Rufina", serif;
  font-size: 16px;
  font-style: italic;
  padding-top: 3px;
}

.twitter-text a:hover {
  color: #232323;
}

.twitter-content .twitter-text p.link a:hover,
.twitter-content .twitter-text p>a:hover,
.footer-top-menu ul li a:hover {
  color: #f07c29;
}

.link-follow ul li {
  display: inline-block;
  margin-right: 8px;
  margin-top: 5px;
}

.link-follow ul li a {
  background: #fff none repeat scroll 0 0;
  border-radius: 100%;
  color: #333333;
  font-size: 18px;
  display: inline-block;
  height: 52px;
  line-height: 52px;
  text-align: center;
  transition: .5s;
  width: 52px;
  text-decoration: none;
  transition: .3s;
}

.link-follow ul li a:hover {
  background: #24902f none repeat scroll 0 0;
  color: #fff;
}

/*----------------------------------------*/
/*  12. Footer
/*----------------------------------------*/

footer {
  /* position: fixed; */
  background: #222222;
  margin-top: 137px;
}
/*----------------------------------------*/
/*  12.1  Footer top
/*----------------------------------------*/

.footer-top-menu {
  padding: 15px 0;
  overflow: hidden;
}

.footer-top-menu ul li {
  display: inline-block;
  margin-right: 25px;
  padding-right: 25px;
  position: relative;
}

.footer-top-menu ul li:before {
  background: #808080 none repeat scroll 0 0;
  content: "";
  height: 13px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}

.footer-top-menu ul li:last-child:before {
  display: none;
}

.footer-top-menu ul li a {
  color: #fff;
  display: block;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  text-decoration: none;
  transition: .3s;
  font-weight: 400;
}

.bb-2 {
  border-bottom: 2px solid #393939;
}
/*----------------------------------------*/
/*  12.2  Footer mid
/*----------------------------------------*/

.single-footer .footer-title h3 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}

.footer-mid-menu ul li a {
  color: #fff;
  display: block;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.5s ease 0s;
  line-height: 30px;
  font-family: Open Sans;
  font-weight: 400;
}

.footer-mid-menu ul li a:hover {
  color: #24902f;
}

.br-2 {
  border-right: 1px solid #393939;
}

.footer-contact p {
  color: #fff;
  font-family: Rufina;
  font-weight: 400;
  font-size:14px;
}

.footer-contact p:last-child {
  margin-bottom: 0;
}

.footer-contact p span {
  color: #fff;
}

.footer-contact p.adress span {
  display: block;
  margin-bottom: 6px;
}
/*----------------------------------------*/
/*  12.3  Footer bottom
/*----------------------------------------*/

.bt-2 {
  border-top: 2px solid #ffffff;
  padding: 30px 0;
 
}

.copy-right-area p {
  color: #fff;
  margin-bottom: 0;
  margin-top: 8px;
  font-family: Open Sans; 
  font-weight: 400;
}

.copy-right-area p a {
  color: #fff;
}

.copy-right-area p a:hover {
  text-decoration: underline;
}

a#scrollUp {
  background: #333 none repeat scroll 0 0;
  border: 2px solid #fff;
  border-radius: 100%;
  bottom: 35px;
  color: #fff;
  display: none;
  font-size: 20px;
  height: 40px;
  line-height: 32px;
  position: fixed;
  right: 12px;
  text-align: center;
  width: 40px;
  z-index: 9999;
  transition: .3s;
}

a#scrollUp:hover {
  background: #f07c29 none repeat scroll 0 0;
  border-color: #f07c29;
}



/*----------------------------------------*/
/*  Top Categories banner
/*----------------------------------------*/

.banner-area-4 .banner-img-2 a img {
  border-radius: 4px;
}

.banner-total {
  overflow: hidden;
}

.banner-total-2 {
  margin-top: 23px;
  overflow: hidden;
}

.banner-total .single-banner-7 {
  float: left;
  padding-right: 15px;
  width: 56%;
}

.banner-total .single-banner-3 {
  float: left;
  padding-left: 15px;
  width: 44%;
}

.banner-total-2 .single-banner-4 {
  float: left;
  padding-right: 15px;
  width: 44%;
}

.banner-total-2 .single-banner-5 {
  float: left;
  padding-left: 15px;
  width: 56%;
}


/*----------------------------------------*/
/*  Breadcumbs
/*----------------------------------------*/
/* .breadcrumbs-area {
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 0;
} */

.breadcrumbs-menu ul li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
}

.breadcrumbs-menu ul li a {
  font-size: 14px;
  color: #333;
  text-decoration: none;
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.breadcrumbs-menu ul li a.active {
  color: #24902f !important;
  font-weight: bolder;
}

.breadcrumbs-menu ul li::before {
  /* background: #ddd none repeat scroll 0 0;
  content: "";
  height: 13px;
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px; */
}

.breadcrumb-item li a.active{
  color: #24902f !important;
  font-weight: bolder;
}
.breadcrumbs-menu ul li:last-child:before {
  display: none;
}
.breadcrumb >.active a {
   color: black;
   font-weight: 500;
   
}

.breadcrumb-item a:hover{
  color: #24902f;
}
.breadcrumb-item a {
color: black;
}

/*----------------------------------------*/
/*  Product details
/*----------------------------------------*/
.product-details .section-title.text-center h3 {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.flex-direction-nav a {
  color: rgba(0, 0, 0, 0);
  font-size: 0;
}

.flex-direction-nav a::before {
  content: "\f104 ";
  font-family: fontawesome !important;
  font-size: 66px !important;
}

.flex-direction-nav a.flex-next::before {
  content: "";
}

.flex-direction-nav a {
  height: 70px;
  margin: -90px 0 0;
  transform: translateY(-50%);
}

.flexslider {
  margin: 0;
}

.flex-control-thumbs img {
  border: 1px solid transparent;
  transition: .3s;
}

.flex-control-thumbs .flex-active {
  border: 2px solid #ff5a09;
}

.product-info-area .nav>li>a {
  background: #fcfcfc none repeat scroll 0 0;
  border: 1px solid #d1d1d1;
  border-bottom: 0;
  border-radius: 0;
  display: block;
  padding: 5px 25px;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.product-info-area .nav>li>a.active {
  background-color: #24902f;
  color: #fff;
}

.product-info-area .tab-content {
  border: 1px solid #d1d1d1;
  padding: 20px;
}

.product-info-area .valu>p {
  font-size: 13px;
  margin-bottom: 15px;
  color: #333;
}

.product-info-area .valu>ul {
  padding-left: 26px;
}

.product-info-area .valu li .review-title h3 {
  font-size: 16px;
  margin-bottom: 25px;
}

.product-info-area .valu li {
  color: #333;
}

.product-info-area .valu li i {
  font-size: 6px;
  padding-right: 8px;
  position: relative;
  top: -2px;
}

.product-info-area .valu .section-title h2 {
  border-bottom: 1px solid #e5e5e5;
  color: #4c4c4c;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 15px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.review-title>h3 {
  font-size: 18px;
  font-weight: 300;
  margin: 0 0 30px;
}

.review-rating span {
  display: inline-block;
  float: left;
  font-weight: bold;
  padding-right: 10px;
}

.rating-result {
  display: inline-block;
  float: left;
}

.rating-result>a {
  color: #24902f;

  line-height: 30px;
  position: relative;
  top: -8px;
}

.rating-result>a i {
  font-size: 15px !important;
  padding: 0 !important;
}

.review-right {
  float: left;
  padding-left: 110px;
}

.review-content>h4 {
  font-size: 15px;
  margin: 0;
  font-weight: 300;
}

.valu {
  overflow: hidden;
}

.review-left {
  display: inline-block;
  float: left;
}

.review-details p {
  font-size: 13px;
  margin: 0;
}

.review-details p a {
  color: #333;
  font-weight: 700;
  padding-left: 12px;
}

.review-details {
  margin-top: 20px;
}

.valu-2 ul li {
  border-bottom: 1px solid #c9c9c9;
  margin: 0;
  overflow: hidden;
  padding: 30px 0;
}

.review-add {
  margin: 30px 0;
}

.review-add h3 {
  color: #333;
  font-size: 22px;
  margin-bottom: 0;
}

.review-add h4 {
  color: #333;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  position: relative;
}

.review-add h4:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -8px;
  background-color: #d1d1d1;
  width: 30%;
  height: 1px;
}

.review-field-ratings {
  overflow: hidden;
  margin-bottom: 25px;
}

.review-field-ratings>span {
  display: inline-block;
  float: left;
  font-size: 22px;
  font-weight: 700;
  padding-right: 30px;
}

.review-field-ratings>span>sup {
  color: #e02b27;
}

.review-field-ratings .control {
  display: inline-block;
  float: left;
}

.review-field-ratings .single-control {
  padding-bottom: 10px;
  overflow: hidden;
}

.review-field-ratings .single-control span {
  float: left;
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
}

.review-field-ratings .single-control .review-control-vote {
  display: inline-block;
  float: left;
  padding-left: 15px;
}

.review-field-ratings .single-control .review-control-vote a {
  color: #666;
  font-size: 16px;
  position: relative;
}

.review-field-ratings .single-control .review-control-vote a:hover {
  color: #24902f;
}

.review-form {
  margin-bottom: 30px;
  overflow: hidden;
  padding-left: 35px;
}

.review-form .single-form {
  margin-bottom: 25px;
  overflow: hidden;
}

.review-form .single-form label {
  font-weight: 700;
  font-size: 13px;
  float: left;
  display: inline-block;
  text-align: right;
}

.review-form .single-form label sup {
  color: #e02b27;
  padding-left: 5px;
}

.review-form .single-form form {
  display: inline-block;
  float: left;
  padding-left: 20px;
}

.review-form .single-form form input {
  border: 1px solid #c2c2c2;
  height: 35px;
  padding: 5px 9px;
  width: 350px;
}

.review-form .single-form form textarea {
  border: 1px solid #c2c2c2;
  padding: 5px 9px;
  width: 350px;
  margin-left: 20px;
}

.review-form .single-form-2 form {
  padding-left: 23px;
}

.review-form-button {
  margin-bottom: 30px;
}

.review-form-button a {
  background: #f2f2f2 none repeat scroll 0 0;
  border: 1px solid #cdcdcd;
  color: #555;
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}

.product-info-main .page-title h1 {
  color: #333333;
  font-size: 36px;
  font-weight: 400;
  margin: 15px 0 9px;
  padding: 0;
  text-transform: capitalize;
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.product-info-stock-sku>span {
  color: #333;
  font-size: 14px;
  /* font-weight: 400; */
  margin-right: 15px;
  text-transform: capitalize;
  /* font-family: 'Open Sans', serif; */
  font-weight: 400;
  float: left;
  display: inline-block;
}

.product-attribute span {
  color: #333;
  font-size: 14px;
  line-height: 20px;
  padding-right: 10px;
  text-transform: uppercase;
}

.product-attribute span.value {
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.product-reviews-summary {
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  display: block;
  margin: 20px 0 0;
  padding: 14px 0 12px;
  overflow: hidden;
}

.rating-summary {
  display: inline-block;
  float: left;
}

.rating-summary>a {
  color: #24902f
  ;
}

.reviews-actions a.view {
  border-left: 1px solid #989898;
  color: #333;
  padding-left: 6px;
}

.reviews-actions a {
  color: #333;
}

.reviews-actions {
  float: left;
  margin-left: 10px;
}

.product-info-price {
  /* margin: 26px 0; */
  overflow: hidden;
}

.product-info-price .price-final {
  color: #24902f;
  font-size: 25px;
  font-weight: 400;
  font-family: open Sans;
  font-weight: 400;
}

.product-info-price .price-final span.old-price {
  color: #909090;
  font-weight: 400;
  text-decoration: line-through;
}

/* .product-add-form form .quality-button input {
  background: #24902f none repeat scroll 0 0;
  border: 0 none;
  color: #fff;
  font-size: 14px;
  height: 50px;
  margin: 0;
  text-align: center;
  width: 70px;
  padding: 0 10px;
} */

.product-add-form form a {
  background: #24902f none repeat scroll 0 0;
  border: 0 none;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  padding: 0 28px;
  text-transform: uppercase;
  width: auto;
  transition: .3s;
}

.product-add-form form .quality-button {
  float: left;
  margin-right: 10px;
}

.product-add-form form a {
  float: left;
}

.product-add-form {
  clear: both;
  margin: 30px 0 20px;
  overflow: hidden;
}

.product-addto-links a {
  background: #000 none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-size: 19px;
  font-weight: 700;
  height: 45px;
  line-height: 45px;
  margin-right: 5px;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 50px;
  transition: .3s;
}

.product-addto-links a:hover,
.product-add-form form a:hover {
  background: #225c28 none repeat scroll 0 0;
}

.product-addto-links-text p {
  clear: both;
  color: #333;
  font-weight: 400;
  line-height: 28px;
  margin: 10px 0 0;
  padding: 30px 0;
}
.mytextarea:focus{
  outline: none !important;
  border:1px solid #259030 !important;
  border-radius: 0% !important;
}
.form-control:focus{
  outline: none !important;
  border:1px solid #259030 !important;
  border-radius: 0% !important;
}


/*----------------------------------------*/
/*  Books page
/*----------------------------------------*/

/* .breadcrumbs-area {
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 0;
} */

.breadcrumbs-menu ul li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
}

.breadcrumbs-menu ul li a {
  font-size: 14px;
  color: #333;
  text-decoration: none;
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.breadcrumbs-menu ul li a.active {
  color: #225c28;
}

/* .breadcrumbs-menu ul li::before {
  background: #818181 none repeat scroll 0 0;
  content: "";
  height: 13px;
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
} */

.breadcrumbs-menu ul li:last-child:before {
  display: none;
}

.section-title-5 h2 {
  color: #3f3f3f;
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  padding: 0 0 13px;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 0;
}

.section-title-5 h2:before {
  background: #24902f none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}

.left-title h4 {
  color: #3f3f3f;
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 0;
}

.left-menu ul li {
  border-bottom: 1px solid #e5e5e5;
}

.left-menu ul li:last-child {
  border-bottom: 0;
}

.left-menu ul li a {
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  padding: 10px 0;
  position: relative;
  text-transform: capitalize;
  text-decoration: none;
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.left-menu ul li a:hover {
  color: #24902f;
}

/* .left-menu ul li a:before {
  content: "";
  display: inline-block;
  font-family: "FontAwesome";
  font-size: 16px;
  margin-right: 20px;
} */

.left-menu ul li a span {
  color: #333;
  display: inline-block;
  font-size: 14px;
  float: right;
}

.color-menu ul.color li {
  display: inline-block;
  margin: 0 5px 5px 0;
}

.color-menu ul.color li a {
  background-color: #1857f7;
  border: 2px solid rgb(218, 218, 218);
  height: 20px;
  line-height: 30px;
  padding-left: 20px;
  transition: all 0.3s ease 0s;
  width: 30px;
}

.color-menu ul li a::before {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #999;
  content: "";
  display: block;
  height: 10px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 10px;
}

.color-menu ul.color li a::before {
  display: none;
}

.color-menu ul.color li a.bg-2 {
  background-color: #d99a09;
}

.color-menu ul.color li a.bg-3 {
  background-color: #eb1a1a;
}

.color-menu ul.color li a.bg-4 {
  background-color: #fff;
}

.random-area {
  overflow: hidden;
}

.left-title-2 h2 {
  border-bottom: 2px solid #333333;
  margin-bottom: 25px;
  padding-bottom: 10px;
  color: #333333;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  text-transform: uppercase;
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.left-title-2 p {
  color: #333333;
  font-size: 14px;
  margin-bottom: 0;
}

.toolbar {
  border-bottom: 1px solid #cccccc;
  overflow: hidden;
}

.shop-tab {
  float: left;
  display: inline-block;
}

.tab-3 {
  float: left;
  display: inline-block;
}

.tab-3 ul li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
}

.tab-3 ul li::before {
  background: #ccc none repeat scroll 0 0;
  content: "";
  height: 80px;
  position: absolute;
  right: -7px;
  width: 1px;
  top: 0;
}

.tab-3 ul li:last-child::before {
  display: none;
}

.tab-3 ul li a {
  color: #666;
  display: inline-block;
  font-size: 15px;
  height: 0;
  line-height: 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.tab-3 ul li a i {
  font-size: 30px;
  padding-right: 8px;
  position: relative;
  top: 6px;
}

.tab-3 ul li a.active,
.tab-3 ul li a:hover {
  color: #24902f;
}

.list-page {
  float: left;
  margin-left: 10px;
  margin-top: 6px;
}

.list-page p {
  color: #333;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}

.list-page-2 {
  float: left;
  margin-left: 10px;
}

.list-page-2 p {
  line-height: 26px;
  margin: 0;
  font-weight: 400;
  color: #333;
}

.field-limiter {
  display: inline-block;
  float: right;
}

.toolbar-sorter span {
  color: #2b2b2b;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.toolbar-sorter .sorter-options {
  border: 1px solid #ccc;
  border-radius: 0;
  color: #908f8f;
  font-size: 14px;
  height: 35px;
  min-width: 95px;
  padding: 0 5px;
  font-family: 'Rufina', serif;
  font-weight: 400;
  color: #333;
}

.toolbar-sorter a {
  color: #808080;
  font-size: 20px;
  line-height: 32px;
  padding-left: 5px;
}

.toolbar-sorter {
  display: inline-block;
  float: right;
  margin-right: 30px;
}

.control select {
  border: 1px solid #ccc;
  border-radius: 0;
  color: #333;
  font-size: 14px;
  height: 35px;
  margin-right: 0;
  min-width: 95px;
  padding: 0 5px;
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.control span {
  color: #2b2b2b;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.shop-main-area #th .row {
  margin-left: -10px;
  margin-right: -10px;
}

.shop-main-area #th .row div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.pagination-area {
  border-top: 1px solid #ccc;
  padding: 15px 0;
}

.pagination-area .list-page p {
  margin-bottom: 0;
  color: #666666;
  font-size: 14px;
  line-height: 32px;
}

.pagination-area .page-number {
  float: right;
}

.pagination-area .page-number ul li {
  display: inline-block;
}

.pagination-area .page-number ul li a {
  color: #333;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 32px;
  padding: 0 4px;
  font-weight: 400;
}

.pagination-area .page-number ul li a.active {
  color: #f07c29;
  font-size: 14px;
}

.product-wrapper-content .product-link {
  opacity: 1;
  padding-bottom: 0px;
  position: relative;
  visibility: visible;
  border-top: 1px solid #ccc;
  padding: 10px 0;
}

.product-wrapper-content .product-link .product-button {
  transform: translateX(0%);
}

.product-wrapper-content .product-link .add-to-link {
  transform: translateX(0%);
}

.product-wrapper-content .product-details h4 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.product-wrapper-content .product-details h4 a {
  font-size: 24px;
}

.product-wrapper-content .product-price ul li {
  font-size: 25px;
}

.product-wrapper-content .product-price ul li.old-price {
  font-size: 18px;
}

.product-wrapper-content .product-details p {
  color: #707070;
  margin: 30px 0 20px;
  font-size: 15px;
}

/* React Pagination */
.pagination > li > a
{
     color:#24902f;
}

.pagination > .active > a
{
    color: white;
    background-color: #24902f !Important;
    border: solid 1px #24902f !Important;
}

.pagination > .active > a:hover
{
    background-color: #24902f !Important;
    border: solid 1px #24902f;
}
.pagination {
  padding: 0;
  display: flex;
  justify-content: center;
}

/* End React Pagination */

.btn-change8{
    height: 60px;
    width: 140px;
    background: rgb(217, 242, 216);
    margin: 20px;
    float: left;
    border:0px;
    color:rgb(31, 29, 29);
    box-shadow: 0 0 1px #ccc;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: linear;
    box-shadow:0px 0 0 #24902f  inset;
}
.btn-change8:hover{
    -webkit-box-shadow: -140px 0 0 #24902f inset;
    color: #fff;
}

body{
  font-family: Hind Siliguri; 
}


/* Slick slider */

.slick-prev {
  /* left: 3% !important; */
  z-index: 1;
  font-size: large !important;
    
}
.slick-next {
  /* right: 3% !important; */
  z-index: 1;
  font-size: large !important;
}



.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 40px !important;
    line-height: 1;
    opacity: .75;
    color: #24902f !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.slick-slide:hover {
  width: 130px;
  color: #24902f;
  transition: .5s linear;
}

.slick-dots li button:before{
color: #24902f !important;
}

.author-view{
  border-radius: 0rem !important;
} 
.authors-title:hover{
  color: #24902f !important;
}
.overlay-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: .3s ease;
  background-color: rgba(0,0,0,0.5);
}
/* end Slick Slider */



.image-container{
  position: relative;
}

.searchbtn{
  margin-left:0rem !important;
}


img:hover{
border-color: #24902f !important;
}



@media (max-width: 991px)
{
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 5.666667%;
    max-width: none;
    margin-left: 1.5rem;
}
}





@media (max-width:991px){
  .logo-img .logo-area a img{
    width: 176px;
  }
  .search-publisher {
    flex: none;
    max-width: 60%;
  }
  .search-publisher-left {
    flex: 0 0 25%;
    max-width: 20%;
  }
  .search-author{
    flex: none;
    max-width: 55%;
  }
  .illustration{

  }
  .input-search{
    width: 300px !important;
  }
  .my-collapse{
    max-width: 75%;
    font-size: small;
  }
  
}

@media  (max-width:1200px){
  .logo-icon{
   max-width: 50%;
  }
  .logobar2{
    max-width: 100%;
  }
  .logo-img img{
    width: 150px;
  }
  .my-collapse{
    max-width: 75%;
    font-size: small;
  }
.header-search form input{
  max-width: 100%;
}
/* logobar search responsiveness */
.header-search form a{   
  margin-right: 33px;
}
}

@media  (max-width:1094px){
  .logo-icon{
   max-width: 100%;
  }
  /* .card-img-top {
    margin-left: 40px !important;
  } */
  .card-body{
    max-width: 100%;
  }

}



/* 404 page */

.section-element-area {
  overflow: hidden;
}
.entry-header p {
  color: #000;
  font-size: 24px;
  margin-bottom: 0;
  font-family: 'Rufina', serif;
  font-weight: 400;
  font-weight: 400;
}
.entry-content p {
  font-size: 15px;
  margin-bottom: 40px;
  color: #333;
}
.entry-content a {
  border: 2px solid #aaa;
  color: #333;
  font-weight: 700;
  padding: 10px 30px;
  transition: .3s;
  text-decoration: none;
  font-family: 'Rufina', serif;
  font-weight: 400;
  font-weight: 400;
}
.entry-content a:hover {
  background: #499e46 none repeat scroll 0 0;
  border-color: #499e46;
  color: #fff;
}

/* 404 page end */

/* About Me */
.blog-single-content p {
  font-size: 16px;
  font-family: 'Rufina', serif;
  font-weight: 400;
  color: #333;
}
.blog-single-content blockquote {
  background: rgba(102, 102, 102, 0.05) none repeat scroll 0 0;
  border-color: #24902f;
  font-family: Yanone Kaffeesatz;
  font-size: 20px;
  font-style: italic;
  margin: 20px 0;
  padding: 20px;
  color: #333;
}
.blog-img {
  overflow: hidden;
  position: relative;
}

/* About Me End */

/*----------------------------------------*/
/*  24. Contact
/*----------------------------------------*/

#googleMap {
  height: 410px;
  width: 100%;
}
.contact-info h3,
.contact-form h3 {
    border-bottom: 2px solid #e8e8e9;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 8px;
    text-transform: uppercase;
}

.contact-info ul li {
  border-bottom: 1px solid #e8e8e9;
  float: left;
  font-size: 14px;
  line-height: 40px;
  margin: 0;
  padding: 0;
  width: 100%;
  color: #333;
}
.contact-info ul li i {
  font-size: 16px;
  margin-right: 5px;
  width: 20px;
}

.contact-info ul li span {
  font-weight: 700;
  font-family: 'Rufina', serif;
  color: #333;
}
.contact-info ul li a {
  color: #333;
  text-decoration: none;
  transition: .3s;
  font-family: 'Rufina', serif;
  font-weight: 400;
}

.contact-info ul li a:hover {
  color: #24902f;
}

.contact-form h3 i {
  padding-right: 8px;
}
.contact-form form .single-form-3 input,
.contact-form .single-form-3 textarea {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #eceff8;
    font-size: 14px;
    margin-bottom: 16px;
    padding: 12px 10px;
    width: 100%;
    color: #333;
    font-family: 'Rufina', serif;
    font-weight: 400;
}

.contact-form form .single-form-3 input::-moz-placeholder,
.contact-form .single-form-3 textarea::-moz-placeholder {
    color: #333 !important;
    opacity: 1;
}

.contact-form form .single-form-3 input::-webkit-input-placeholder,
.contact-form .single-form-3 textarea::-webkit-input-placeholder {
    color: #333 !important;
    opacity: 1;
}

.contact-form .single-form-3 textarea {
    height: 200px;
}

.contact-form form .single-form-3 button {
    background: #24902f none repeat scroll 0 0;
    border: medium none;
    border-radius: 0;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    height: 40px;
    margin-top: 16px;
    padding: 0 15px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    width: 140px;
    font-family: 'Rufina', serif;
    font-weight: 400;
}

.contact-form form .single-form-3 button:hover {
    background: #225c28 none repeat scroll 0 0;
}

/*----------------------------------------*/
/*   Contact End
/*----------------------------------------*/


/*----------------------------------------*/
/*  LogoBar2 Start
/*----------------------------------------*/
.logobar2 .my-cart {
  display: inline-block;
  float: left;
  margin-top: -8px;
}
.logobar2 .my-cart ul li {
  position: relative;
}
.logobar2 .my-cart ul>li>a {
  color: #444444;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  padding: 10px 0;
  text-transform: uppercase;
  text-decoration: none;
  transition: .3s;
  margin-top: 12px;
}
.logobar2 .my-cart ul>li:hover a {
  color: #259030;
}
.logobar2 .my-cart ul li>a>i {
  font-size: 25px;
  padding-right: 25px;
  margin-top: 10px;
}
.logobar2 .my-cart ul li>span {
  background: #259030 none repeat scroll 0 0;
  border-radius: 50%;
  bottom: 25px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  height: 25px;
  left: 20px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  width: 25px;
}
.mini-cart-sub {
  position: absolute;
  background-color: #fff;
  border: medium none;
  border-radius: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  display: block;
  left: auto;
  top: 150%;
  opacity: 0;
  padding: 20px;
  right: 0;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 280px;
  z-index: 99999;
}
.logobar2 .my-cart ul>li:hover .mini-cart-sub {
  opacity: 1;
  visibility: visible;
  top: 110%;
}
.cart-product .single-cart {
  display: flex;
  margin-bottom: 15px;
  overflow: hidden;
}
.cart-product .single-cart .cart-img {
  flex: 0 0 65px;
  margin-right: 15px;
}
.cart-product .single-cart .cart-img a img {
  width: 100%;
}

.cart-product .single-cart .cart-info {
  overflow: hidden;
  padding-right: 10px;
  position: relative;
}
.cart-icon {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}
.cart-icon a i {
  color: #232323;
  transition: .3s;
}
.cart-icon a:hover i {
  color: #24902f;
}

.cart-product .single-cart .cart-info h5 {
  margin-bottom: 0px;
}

.logobar2 .my-cart ul>li .cart-product .single-cart .cart-info h5 a {
  color: #666;
  font-family: Rufina;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.logobar2 .my-cart ul>li .cart-product .single-cart .cart-info h5 a:hover,
.logobar2 .my-cart ul>li .cart-bottom a:hover {
    color: #24902f;
}
.cart-product .single-cart .cart-info p {
  margin-bottom: 0;
  font-family: Rufina;
  font-size: 15px;
}

.cart-totals {
  margin-bottom: 15px;
}

.cart-totals h5 {
  color: #606060;
  display: block;
  font-size: 15px;
  font-weight: 400;
  font-family: Rufina;
  margin: 0;
  text-transform: uppercase;
}

.cart-totals h5 span {
  display: block;
  float: right;
}
.logobar2 .my-cart ul>li .cart-bottom a.view-cart {
  margin-bottom: 10px;
}
.logobar2 .my-cart ul>li .cart-bottom a {
  background-color: #606060;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 36px;
  line-height: 24px;
  padding: 7px 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  transition: .3s;
  font-family: Rufina;
}

.logo-nav{
  width: -webkit-fill-available;
}
.logobar2 .logo-icon ul li a i:hover{
  color: #24902f;
}
.logo-login a{
  text-decoration: none;
  color: #263238 !important;
}
.logo-login a:hover{
  text-decoration: none;
  color: #24902f !important;
}

/*----------------------------------------*/
/*  LogoBar2 End
/*----------------------------------------*/

/*======================
    404 page Latest
=======================*/


.page_404{
   padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
    margin-left: 160px;
 }
 
 .content-title{
   margin-left: 160px;
 }

 .content-title h1{
   font-size: 150px;
   font-family: "Open Sans";
   font-weight: bold;
 }
 
 .four_zero_four_bg h1{
      font-size:80px;
     font-family: 'Open Sans'
 }
 
  .four_zero_four_bg h3
  {
		font-size:80px;
    
	}
			 
	.link_404{			 
	  color: #fff!important;
    padding: 10px 20px;
    background: #259030;
    margin: 20px 0;
    display: inline-block;
    font-family: 'Open Sans'
    
  }
	.contant_box_404{
     margin-top:-50px;
     margin-left: 160px;
     font-family: 'Open Sans'
    }

/*======================
    404 page Latest end
=======================*/

/* ========================
      NAVBAR NEW
======================== */

/* .outer-container{
  background-image: url("/img/banner/27.jpg");
} */

@media screen and (max-width: 768px) {
  .responsive-image img{
    width: 150px;
  }
  #hotlineRemove{
    display: none;
  }
  #wishlistRemove{
    display: none;
  }
  #cartRemove{
    display: none;
  }
  #loginRemove{
    display: none;
  }
  #homeRemove{
    display: block;
  }
  #allBookRemove{
    display: block;
  }
  #favouriteBookRemove{
    display: block;
  }
  #writerRemove{
    display: block;
  }
  #subjectRemove{
    display: block;
  }
  #publisherRemove{
    display: block;
  }
  #preOrderRemove{
    display: block;
  }
  #packageRemove{
    display: block;
  }
  #bookFareRemove{
    display: block;
  }
  #blogRemove{
    display: block;
  }
  #mobileHeaderPrevious{
    display: none!important;
  }
  #navSearchField{
    display: none;
  }
  #wishlistResponsiveScreen{
    display: block;
  }
  #cartResponsiveScreen{
    display: block;
  }
  #responsiveSearchField{
    display: block!important;
  }

  .my-cart{
    padding-right: 14px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .mobile-view-nav-content{
    margin-bottom: 10px;
    font-size: 1.25rem;
  }
  .mobile-view-dropdown-disappeare{
    display: none;
  }

  .slick-prev
  {
      left: 0px!important;
  }
  
  .slick-next
  {
      right: 20px!important;
  }

  .block-newsletter {
    padding: 45px 225px 55px;
  }

  .multirow-slider{
    padding-left: 57px;
  }
  
}


.test{
  padding: 0;
}
.argha{
  display: none;
}
.nav-container{
  padding-left: 0;
  padding-right: 0;
}
.hotline{
  display: flex;
  justify-content: center;
  align-items: center;
}
.home{
  display: none;
}
.all-book{
  display: none;
}
.favourite-book{
  display: none;
}
.writer{
  display: none;
}
.subject{
  display: none;
}
.publisher{
  display: none;
}
.disappeare{
  display: none;
}
.nav-searchField{
  outline: "none"; 
  width: "300px"
}
#responsiveSearchField{
  display: none;
}

.floating-button-renew{
  position: fixed;
  bottom: 50px;
  right: 50px;
  border: 1px solid #e7e7e7;
  background: linear-gradient(#fdfdfd, #FFFFFF);
  box-shadow:  0 0 3px 2px rgb(231 231 231 / 75%);
  text-align: center;
  padding: 22px 6px 0px 6px;
  border-radius: 4px;
  z-index: 3040;
}
.floating-button-renew-2{
  position: fixed;
  bottom: 145px;
  right: 50px;
  border: 1px solid #e7e7e7;
  background: linear-gradient(#fdfdfd, #FFFFFF);
  box-shadow:  0 0 3px 2px rgb(231 231 231 / 75%);
  text-align: center;
  padding: 22px 6px 0px 0px;
  border-radius: 4px;
  z-index: 3040;
}

.new-properties{
  height: 100%!important;
  width: 100%!important;
}
.carousel-image{
  width: 132px;
  height: 185px;
}

@media screen and (max-width: 540px) {
  .floating-button-renew{
    bottom: 25px!important;
    right: 25px!important;
  }
  .floating-button-renew-2{
    bottom: 115px!important;
    right: 25px!important;
  }
  .carousel-image{
    width: 100%!important;
    height: 100%!important;
  }

  .slick-prev
{
    left: 0px!important;
}

.slick-next
{
    right: 20px!important;
}

.block-newsletter {
  padding: 45px 65px 55px;
}

.multirow-slider{
  padding-left: 20px;
}

}

@media screen and (max-width: 1024px) {
  .slick-prev
  {
      left: 0px!important;
  }
  
  .slick-next
  {
      right: 20px!important;
  }

  .multirow-slider{
    margin-left: 5px;
  }

}

.mydrop:active{
  background-color: #259030;
}

/* Three category books slider arrows */
.sliderOne .slick-prev{
  top: -55px !important;
  left: 170px !important;
 
}
.sliderOne .slick-next{
  top: -55px !important;
  left: 200px !important;
  
}

.sliderOne .slick-prev:before{
  font-size: 25px !important;
}
.sliderOne .slick-next:before{
  font-size: 25px !important;
}
.sliderTwo .slick-prev{
  top: -55px !important;
  left: 150px !important;
}
.sliderTwo .slick-next{
  top: -55px !important;
  left: 180px !important;
}
.sliderTwo .slick-prev:before{
  font-size: 25px !important;
}
.sliderTwo .slick-next:before{
  font-size: 25px !important;
}

.sliderThree .slick-prev{
  top: -55px !important;
  left: 150px !important;
}


.sliderThree .slick-next{
  top: -55px !important;
  left: 180px !important;
}

.sliderThree .slick-prev:before{
  font-size: 25px !important;
}
.sliderThree .slick-next:before{
  font-size: 25px !important;
}
.related-slider .slick-prev{
  top: -35px !important;
  left: 180px !important;
}
.related-slider .slick-next{
  top: -35px !important;
  left: 210px !important;
}
.related-slider .slick-prev:before{
  font-size: 25px !important;
}
.related-slider .slick-next:before{
  font-size: 25px !important;
}
/* Three category books slider arrows end*/