.myaccount-tab-menu {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.myaccount-tab-menu a {
  border: 1px solid #efefef;
  border-bottom: none;
  color: #222222;
  font-weight: 400;
  font-size: 15px;
  display: block;
  padding: 10px 15px;
  text-transform: capitalize;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #efefef;
}

.myaccount-tab-menu a:hover,
.myaccount-tab-menu a.active {
  background-color: #259030;
  border-color: #259030;
  color: #fff;
  text-decoration: none;
}

.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767.98px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
}

@media only screen and (max-width: 575.98px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content h5 {
  border-bottom: 1px dashed #ccc;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.myaccount-content .welcome a {
  color: #222222;
}

.myaccount-content .welcome a:hover {
  color: #259030;
}

.myaccount-content .welcome strong {
  font-weight: 500;
  color: #259030;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  color: #222222;
  font-size: 20px;
  margin-top: 20px;
  font-weight: 600;
  border-bottom: 1px dashed #ccc;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}
.myaccount-table a {
  background: #499e46 none repeat scroll 0 0;
  border: 2px solid #499e46;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  /* line-height: 30px; */
  /* margin-top: 43px; */
  padding: 7px 25px;
  text-transform: none;

  font-weight: 400;
  text-decoration: none;
}

.myaccount-table a:hover {
  background: #333 none repeat scroll 0 0;
  border-color: #333;
  transition: all 300ms ease-in 0s;
}

.myaccount-table table th,
.myaccount-table .table th {
  color: #222222;
  padding: 10px;
  font-weight: 400;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #f4f5f7;
  border-top: 3px solid #259030;
  border-radius: 5px 5px 0 0;
  font-weight: 400;
  font-size: 15px;
  color: #555555;
  padding: 20px;
}

.account-details-form .single-input-item input,
.single-input-item textarea {
  color: #555555;
  border: 1px solid #ccc;
  padding: 12px 10px;
  width: 100%;
  font-size: 14px;
  background: #f9f9f9;
}

.account-details-form .single-input-item {
  margin-top: 20px;
}

.account-details-form .single-input-item label {
  color: #222222;
  text-transform: capitalize;
  font-size: 14px;
}
.mytable tr td {
  border-top: none;
}

.mytable th {
  border-top: none;
}


