.single-register {
    margin-bottom: 15px;
    overflow: hidden;
    
}

.single-register form {
    
}

.billing-fields{
    border: 1px solid #259030;
    padding: 25px;
    margin-left: 10px;
}

.single-register form label,
.single-register label {
    color: #333;
    display: block;
    font-size: 16px;
    font-weight: 400;
    margin-top: 7px;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.single-register  input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #eceff8;
    box-shadow: none;
    color: #333;
    font-size: 14px;
    height: 45px;
    padding-left: 10px;
    width: 100%;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.single-register  input:focus {
    outline: none !important;
    border:1px solid #259030;
    /* box-shadow: 0 0 10px #259030; */
}
.single-register  textarea:focus {
    outline: none !important;
    border:1px solid #259030;
    box-shadow: 0 0 0px #259030;
}

.single-register .chosen-select {
    height: 45px;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #eceff8;
    color: #626262;
    font-size: 14px;
    height: 45px;
    padding-left: 10px;
    box-shadow: none;
    text-align: left;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.single-register input#billing_address_2 {
    margin-top: 15px;
}

.single-register-3 #rememberme {
    float: left;
}

.single-register-3 label {
    float: left;
    margin-top: 0;
    padding-left: 10px;
    font-size: 13px;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.single-register-3 label a {
    color: #259030;
    font-size: 14px;
    text-decoration: none;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.single-register>a {
    background: #259030 none repeat scroll 0 0;
    border: 1px solid #259030;
    box-shadow: none;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    margin-top: 6px;
    padding: 12px 48px;
    text-decoration: none;
    transition: .3s;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.single-register>a:hover {
    background-color: #225c28;
    color: #fff;
    border: 1px solid #225c28;
}
