.blog-comment-readmore {
    border-top: 2px dotted #e5e5e5;
    margin-bottom: 50px;
    overflow: hidden;
    padding-top: 20px;
}

.blog-comment-readmore .blog-readmore {
    float: left;
}

.blog-comment-readmore .blog-readmore a {
    color: #333;
    text-decoration: underline;
    text-decoration: none;
    transition: .3s;
    font-family: 'Rufina', serif;
    font-weight: 400;
}

.blog-comment-readmore .blog-readmore a:hover {
    color: #259030;
}

.blog-comment-readmore .blog-readmore a i {
    padding-left: 10px;
}

.blog-comment-readmore .blog-com {
    float: right;
}

.blog-comment-readmore .blog-com a {
    color: #333;
    text-decoration: none;
    font-family: 'Rufina', serif;
    font-weight: 400;
}

.youtube-video iframe {
    height: 430px;
    width: 100%;
}

.blog-audio audio {
    width: 100%;
}

.blog-pagination ul li {
    display: inline-block;
    margin: 0 2px;
}

.blog-pagination ul li a {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ebebeb;
    color: #259030;
    display: block;
    font-size: 15px;
    height: 35px;
    line-height: 32px;
    text-align: center;
    transition: all 0.3s ease 0s;
    width: 35px;
}

.blog-pagination ul li a:hover,
.blog-pagination ul li.active a {
    background: #259030 none repeat scroll 0 0;
    border: 1px solid #259030;
    color: #f1f1f1;
    text-decoration: none;
}






/* Blog Details */

.blog-main-area {
    overflow: hidden;
}

.blog-left-title {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 5px;
    margin-bottom: 20px;
}

.blog-left-title h3 {
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Rufina', serif;
    font-weight: 400;
}

.side-form form {
    position: relative;
}

.side-form form a {
    color: #333;
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.side-form form input {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ebebeb;
    color: #333;
    height: 40px;
    padding-left: 10px;
    width: 100%;
    font-family: 'Rufina', serif;
    font-weight: 400;
    padding-right: 40px;
}

.side-form form input:focus{
    outline: none !important;
    border:1px solid #259030;
}


.blog-side-menu ul li,
.catagory-menu ul li {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 5px;
    margin-top: 15px;
    display: block;
}

.blog-side-menu ul li a,
.catagory-menu ul li a {
    color: #333;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: 15px;
    transition: .3s;
    font-family: 'Rufina', serif;
    font-weight: 400;
}

.blog-side-menu ul li a:hover,
.catagory-menu ul li a:hover {
    color: #259030;
    
}

.blog-side-menu ul li:first-child {
    margin-top: 0;
}

.blog-side-menu ul li a i {
    padding-right: 14px;
}

.catagory-menu ul li {
    position: relative;
}

.catagory-menu ul li ul.category-sub li {
    padding-left: 10px;
}

.catagory-menu ul li ul.category-sub li:last-child {
    border-bottom: 0 none;
}

.catagory-menu ul li ul.category-sub li a {
    font-size: 15px;
}

.catagory-menu ul li a {
    cursor: pointer;
    display: block;
}

.catagory-menu ul li.has-sub::before {
    content: "\f067 ";
    cursor: pointer;
    font-family: fontawesome;
    font-size: 15px;
    position: absolute;
    right: 0;
    top: 0px;
    cursor: pointer;
}

.product-area .single-catagory .catagory-menu ul li.has-sub.open::before {
    content: "\f068";
}

.category-sub {
    display: none;
}

.blog-tag ul li {
    display: inline-block;
}

.blog-tag ul li a {
    background: #ebebeb none repeat scroll 0 0;
    border: 1px solid #ebebeb;
    color: #333;
    display: block;
    font-size: 13px;
    padding: 7px 10px;
    transition: all 0.3s ease 0s;
    margin-bottom: 10px;
    text-decoration: none;
}

.blog-tag ul li a:hover {
    background: #259030 none repeat scroll 0 0;
    border: 1px solid #259030;
    color: #fff;
}

.author-destils {
    overflow: hidden;
}

.author-destils .author-left {
    display: inline-block;
    float: left;
    width: 65%;
}

.author-destils .author-left .author-img {
    display: inline-block;
    float: left;
    margin-right: 10px;
    width: 10%;
}

.author-destils .author-left .author-description {
    float: left;
    margin-top: 15px;
    overflow: hidden;
    width: 80%;
}

.author-destils .author-left .author-description p {
    margin: 0;
    font-family: 'Rufina', serif;
    color: #333;
    font-weight: 400;
}

.author-destils .author-left .author-description p a {
    color: #333;
    font-family: 'Rufina', serif;
    font-weight: 400;
}

.author-destils .author-left .author-description p a span {
    color: #333;
    font-weight: 700;
    margin: 0 2px;
}

.author-destils .author-left .author-description span {
    margin-top: 3px;
    color: #333;
}

.author-destils .author-right {
    display: inline-block;
    float: right;
    margin-top: 23px;
    width: 30%;
}

.author-destils .author-right span {
    color: #333;
    font-family: Yanone Kaffeesatz;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 8px;
    float: left;
}

.author-destils .author-right ul {
    float: right;
    margin-top: -8px;
}

.author-destils .author-right ul li {
    display: inline-block;
    margin-left: 15px;
}

.author-destils .author-right ul li a {
    font-size: 16px;
    color: #333;
    transition: .3s;
}

.author-destils .author-right ul li a:hover {
    color: #259030;
}

.single-blog-content .single-blog-title h3 a,
.single-blog-content .single-blog-title h3 {
    color: #252525;
    font-size: 30px;
    font-weight: 500;
    transition: .3s;
    text-decoration: none;
    font-family: 'Rufina', serif;
    font-weight: 400;
}

.single-blog-content .single-blog-title h3 a:hover {
    color: #259030;
}

.single-blog-content .blog-single-content p {
    font-size: 16px;
    font-family: 'Rufina', serif;
    font-weight: 400;
    color: #333;
}

.single-blog-content .blog-single-content blockquote {
    background: rgba(102, 102, 102, 0.05) none repeat scroll 0 0;
    border-color: #259030;
    font-family: Yanone Kaffeesatz;
    font-size: 20px;
    font-style: italic;
    margin: 20px 0;
    padding: 20px;
    color: #333;
}

.comment-tag {
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    margin-top: 20px;
    padding-top: 10px;
}

.comment-tag p {
    color: #333;
    font-size: 14px;
}

.blog-img {
    overflow: hidden;
    position: relative;
    
}

.blog-img a img {
    transform: scale(1);
    transition: .4s;
}

.blog-img a img:hover {
    transform: scale(1.1);
}

.sharing-post {
    overflow: hidden;
}

.sharing-post .share-text {
    float: left;
}

.sharing-post .share-text span {
    font-size: 20px;
    font-style: normal;
    line-height: 18px;
    margin-right: 30px;
    color: #333;
}

.sharing-post .share-icon {
    float: right;
}

.sharing-post .share-icon ul li {
    display: inline-block;
    padding-left: 15px;
}

.sharing-post .share-icon ul li a {
    font-size: 16px;
    color: #333;
    text-decoration: none;
    transition: .3s;
}

.sharing-post .share-icon ul li a:hover {
    color: #259030;
}

.comment-title-wrap {
    border-bottom: 2px solid #ebebeb;
    text-transform: uppercase;
    position: relative;
}

.comment-title-wrap:before {
    background: #259030 none repeat scroll 0 0;
    bottom: -2px;
    content: "";
    height: 2px;
    position: absolute;
    width: 120px;
}

.comment-title-wrap h3 {
    font-weight: 700;
    color: #333;
}

.comment-reply-wrap ul li {
    margin-bottom: 20px;
    overflow: hidden;
}

.comment-reply-wrap ul li:last-child {
    margin-bottom: 0;
}

.comment-reply-wrap ul li .public-comment-2 {
    margin-left: 35px;
}

.comment-reply-wrap ul li .public-comment .comment-img {
    float: left;
    margin-right: 26px;
    overflow: hidden;
    width: 8%;
}

.comment-reply-wrap ul li .public-comment .public-text {
    border: 1px solid #ebebeb;
    border-radius: 3px;
    float: left;
    padding: 15px;
    position: relative;
    width: 88%;
}

/* .comment-reply-wrap ul li .public-comment .public-text::before {
    background: #fff none repeat scroll 0 0;
    border-bottom: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    content: "";
    display: block;
    height: 10px;
    left: -5px;
    position: absolute;
    top: 10px;
    transform: rotate(45deg);
    width: 10px;
} */

.comment-reply-wrap ul li .public-comment .public-text .single-comm-top>a {
    color: #333;
    font-size: 17px;
    font-weight: 700;
    text-decoration: none;
}

.comment-reply-wrap ul li .public-comment .public-text .single-comm-top p {
    color: #333;
    font-size: 14px;
    margin-bottom: 8px;
}

.comment-reply-wrap ul li .public-comment .public-text .single-comm-top p a {
    color: #333;
    font-size: 15px;
    padding-left: 40px;
    text-decoration: none;
    transition: .3s;
}

.comment-reply-wrap ul li .public-comment .public-text .single-comm-top p a:hover {
    color: #259030;
}

.comment-reply-wrap ul li .public-comment .public-text p {
    color: #333;
    font-size: 14px;
    margin-bottom: 0;
}

.comment-input p {
    color: #333;
}

.comment-input .comment-input-textarea form label {
    color: #333;
    display: block;
}

.comment-input .comment-input-textarea textarea,
.comment-input .single-comment-input input {
    background: #f5f5f5 none repeat scroll 0 0;
    border: 1px solid #ebebeb;
    height: 150px;
    padding: 15px;
    width: 100%;
    color: #333;
}

.comment-input .comment-input-textarea textarea,
.comment-input .single-comment-input input:focus {
    outline: none !important;
    border:1px solid #259030;
}

.comment-input .single-comment-input label {
    color: #333;
    display: block;
}

.comment-input .single-comment-input input {
    background: #f5f5f5 none repeat scroll 0 0;
    border: 1px solid #ebebeb;
    height: 45px;
    padding-left: 15px;
    width: 100%;
}

.single-post-button button {
    background: #259030 none repeat scroll 0 0;
    border: 1px solid #259030;
    color: #fff;
    font-size: 13px;
    padding: 9px 15px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    text-decoration: none;
    display: inline-block;
}

.single-post-button button:hover {
    background: #265e35 none repeat scroll 0 0;
    border: 1px solid #265e35;
}