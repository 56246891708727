.user-login-area {
    overflow: hidden;
    background: #fff;
}

.login-title h2 {
    color: #202020;
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 15px;
    padding-bottom: 10px;
    text-transform: capitalize;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.login-title p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    line-height: 1.6em;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    color: #333;
}

.login-form {
    border: 1px solid #259030;
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    padding: 25px 30px;
    width: 100%;
}

.login-form .single-login {
    margin-bottom: 15px;
}

.login-form .single-login label {
    color: #333;
    font-size: 15px;
    font-weight: 400;
    margin-top: 7px;
    display: block;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.login-form .single-login input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #eceff8;
    padding: 12px 10px;
    width: 100%;
    font-family: 'Rufina', serif;
    font-weight: 400;
}
.login-form .single-login input:focus {
    outline: none !important;
    border:1px solid #259030;
    /* box-shadow: 0 0 10px #259030; */
}

.login-form .single-login a {
    /* background: rgba(0, 0, 0, 0) none repeat scroll 0 0; */
    border: 1px solid #259030;
    display: inline-block;
    padding: 10px 43px;
    color: #fff;
    text-transform: capitalize;
    text-decoration: none;
    float: left;
    transition: .3s;
    font-family: 'Open Sans', serif;
    font-weight: 400;
     background: #259030;
}

.login-form .single-login a:hover {
    background: #225c28;
    color: #fff;
    border: 1px solid #225c28;
}

.single-login input#rememberme {
    float: left;
    width: 7%;
    margin-top: 14px;
}

.single-login-2 span {
    float: left;
    margin-top: 10px;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    color: #333;
}

.single-login-2 {
    overflow: hidden;
}

.login-form>a {
    color: #259030;
    line-height: 24px;
    margin-bottom: 15px;
    font-size: 14px;
    text-decoration: none;
}

.login-form>a:hover{
     transition:  .4s;
color: #225c28;
font-weight: bold;
}

